import React from 'react';
import { useQuery } from '@apollo/client';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import { useDispatch } from 'react-redux';
import { setMobileMenuOpen, setSideMenuOpen } from '../../../header/actions';
import { NavLink } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { ABOUT_PAGES } from '../queries';
import './about-page-menu.scss';
import ShortArrow from '../../../util/icons/components/ShortArrow';

const Menu = ({ match }) => {
  const { loading, error, data } = useQuery(ABOUT_PAGES);
  const dispatch = useDispatch();
  const handleSelection = () => {
    dispatch(setMobileMenuOpen(false));
    window.scrollTo({ top: 0 });
  };
  let content = null;
  if (loading) {
    content = (
      <li className='top-level'>
        <LoadingIcon />
      </li>
    );
  } else if (error) {
    content = (
      <li className='top-level'>
        <p>error loading content</p>
      </li>
    );
  } else {
    content = data.aboutPages.map(page => (
      <li key={ page.slug } className='top-level' onClick={ handleSelection }>
        <NavLink to={`${match.url}/${page.slug}`}
          className='about-page-menu-item'
          activeClassName='active'>
          <div className="text" data-cy={ `menu-option-${page.slug}` }>
            { page.menuTitle }
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    ));
  }

  return (
    <ul data-cy="about-menu" className='about-page-menu'>
      <li className="header">
        <span className="brand">Good</span>
        <span className="standard">Beef</span>
      </li>
      <li className="back" onClick={ () => dispatch(setSideMenuOpen(false)) }>
        <ShortArrow />
        <span className='text'>Main Menu</span>
      </li>
      { content }
    </ul>
  );
};

Menu.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default Menu;
