import { gql } from '@apollo/client';
import { getGraphCMSStage } from '../../util/api';

export const ABOUT_PAGES = gql`
{
  aboutPages(stage: ${getGraphCMSStage()}) {
    menuTitle
    slug
  }
}
`;
