import React from 'react';

/* eslint-disable max-len */
const ShortArrow = () => (
  <svg className="short-arrow" viewBox="0 0 99.171 81.321">
    <polygon points="58.51 0 51.754 6.756 80.881 35.883 0 35.883 0 45.438 80.881 45.438 51.754 74.565 58.51 81.321 99.171 40.661 58.51 0"/>
  </svg>
);

export default ShortArrow;
