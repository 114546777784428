import React from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '../../apolloClient';
import './about-pages.scss';
import { PropTypes } from 'prop-types';
import About from './About';

const Provider = ({ match }) => {
  return (
    <ApolloProvider client={ apolloClient } >
      <About match={ match } />
    </ApolloProvider>
  );
};

Provider.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    }),
    url: PropTypes.string
  })
};

export default Provider;
